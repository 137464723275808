/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react";
import { Amplify } from 'aws-amplify';
import "./src/assets/css/global.css";
//import { AmplifyProvider } from '@aws-amplify/ui-react';
//import '@aws-amplify/ui-react/styles.css'; // default theme
import awsExports from './src/aws-exports';
Amplify.configure(awsExports);
import { Provider } from "react-redux"
import configureStore from "./src/state/createStore"

export const wrapRootElement = ({ element, props }) => {
  return <Provider store={configureStore}>{element}</Provider>
}
