/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:7c77b3a7-09c4-421f-a91e-e3aa9afc42a6",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_QJBDveKtv",
    "aws_user_pools_web_client_id": "4s52b3e4ope8k38jba678c2msp",
    "oauth": {
        "domain": "chivalryduelmasters3b434aa1-3b434aa1-master.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.chivalryduelmasters.com/auth/,https://develop.d27fqyvizc134k.amplifyapp.com/auth/,http://localhost:8000/auth/",
        "redirectSignOut": "https://www.chivalryduelmasters.com/auth/,https://develop.d27fqyvizc134k.amplifyapp.com/auth/,http://localhost:8000/auth/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://ev622pbi7jgtppgrthb2ulvug4.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-jfewdbjl3bd65mvifof5ej4sky"
};


export default awsmobile;
