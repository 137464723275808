exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-js": () => import("./../../../src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-pages-duel-rankings-jsx": () => import("./../../../src/pages/duel-rankings.jsx" /* webpackChunkName: "component---src-pages-duel-rankings-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-match-jsx": () => import("./../../../src/pages/match.jsx" /* webpackChunkName: "component---src-pages-match-jsx" */),
  "component---src-pages-my-account-jsx": () => import("./../../../src/pages/my-account.jsx" /* webpackChunkName: "component---src-pages-my-account-jsx" */),
  "component---src-pages-submit-match-jsx": () => import("./../../../src/pages/submit-match.jsx" /* webpackChunkName: "component---src-pages-submit-match-jsx" */)
}

